<template>
  <div class="view-task">
    <a-table tid="1" :loading="loading" :size="tableSize" :columns="columns" :data-source="taskList" rowKey="id" :pagination="false">
      <div class="task-name" slot="taskName" slot-scope="text, record">
        <span class="task-name-custom"> {{ record.taskName }}</span>
        <span class="light-gray">{{ record.remark }}</span>
      </div>
      <div class="light-gray" slot="createBy" slot-scope="text, record">
        <span> {{ record.createBy }}</span>
      </div>
      <div slot="routeName" slot-scope="text, record">
        <a class="route-name" href="#">
          <span @click="toViewRoute(record)">{{ record.routeName }}</span></a
        >
      </div>
      <div slot="taskState" slot-scope="text, record">
        {{ taskStateFormat(record) }}
      </div>
      <div slot="taskType" slot-scope="text, record">
        {{ taskTypeFormat(record) }}
      </div>
      <div slot="taskSource" slot-scope="text, record">
        {{ taskSourceFormat(record) }}
      </div>
      <div slot="vehicleCreate" slot-scope="text, record">
        {{ vehicleCreateFormat(record) }}
      </div>
      <div slot="action" slot-scope="text, record">
        <template v-if="record.taskState === '0'">
          <a @click="updateTaskTemplate(record)"> <a-icon type="info-circle" />修改 </a>
        </template>
        <template>
          <a-divider type="vertical" v-if="record.taskState === '0'" />
          <a @click="deleteTaskTemplate(record)"> <a-icon type="info-circle" />删除 </a>
        </template>
        <template v-if="record.vehicleCreate !== 0 && record.taskState == '0'">
          <a-divider type="vertical" v-if="record.taskState === '0'" />
          <a @click="handleCreateTask(record)"> <a-icon type="info-circle" />同步车端任务 </a>
          <!-- <a-divider v-if="record.vehicleCreate === 0" type="vertical" /> -->
        </template>
        <!-- <template v-if="record.vehicleCreate === 0">
          <a @click="addExecTask(record)"> <a-icon type="info-circle" />新增执行任务 </a>
        </template> -->
      </div>
    </a-table>
    <!-- 分页 -->
    <a-pagination
      class="ant-table-pagination"
      show-size-changer
      show-quick-jumper
      :current="queryParam.pageNum"
      :total="total"
      :page-size="queryParam.pageSize"
      :showTotal="(total) => `共 ${total} 条`"
      @showSizeChange="onShowSizeChange"
      @change="changeSize"
    />

    <route-plan-modal v-if="routePlanVisible" :vehicleId="vehicleId" :routeId="curRouteId" @closeRoutePlanModal="handleCloseRoutePlanModal" :position="{ top: '4%', left: '52%' }" />
    <!-- 查看任务详情 -->
    <vxe-modal v-model="TaskDetailVisible" width="80%" height="80%" :title="vehicleId + '-任务记录'" :show-zoom="true" resize :lock-view="false" :mask="false" @close="handleCloseTaskDetailModal">
      <vehicleTaskRecord :breadcrumb="false" :taskId="taskId" />
    </vxe-modal>

    <create-exec-task-modal
      :info="info"
      v-if="createExecTaskVisible"
      ref="createExecTaskModalRef"
      @closeTaskModal="handleCloseSendTaskModal"
      :position="{ top: '10%', left: '45%' }"
      :is-show-modal="createExecTaskVisible"
      :vehicle-id="vehicleId"
      :taskForm="taskForm"
      :taskTemplateForm="taskTemplateForm"
      :withDynamic="withDynamic"
      @update="handleUpdate"
    />
    <create-task-template
      :info="info"
      ref="createTaskTemplateRef"
      v-if="createTaskTemplateVisible"
      @closeTaskModal="handleCloseCreateTaskTemplateModal"
      :position="{ top: '20%', left: '45%' }"
      :is-show-modal="createTaskTemplateVisible"
      :vehicle-id="vehicleId"
      :taskForm="taskForm"
      :withDynamic="withDynamic"
      @update="handleUpdate"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { createTask, syncTask, listVehicleTask, createTaskAck, syncTaskAck, delTask, delTaskAck } from '@/api/iot/vehicleTask'
import RoutePlanModal from '@/views/iot/routePlanning/modules/RoutePlanModal.vue'
import TaskBase from './TaskBase.js'
import vehicleTaskRecord from '@/views/iot/vehicleTaskRecord'
import { mapState } from 'vuex'
import CreateExecTaskModal from './CreateExecTaskModal'
import { forceQuit } from '@/api/iot/taskScheduler'
import CreateTaskTemplate from './CreateTaskTemplate'
import { MsgAnswer } from '@/utils/util.js'
const columns = [
  {
    title: '名称及备注',
    key: 'taskName',
    dataIndex: 'taskName',
    scopedSlots: { customRender: 'taskName' },
    width: 150
  },
  {
    title: '任务类型',
    key: 'taskType',
    dataIndex: 'taskType',
    visible: false,
    scopedSlots: { customRender: 'taskType' }
  },
  {
    title: '任务来源',
    key: 'taskSource',
    dataIndex: 'taskSource',
    scopedSlots: { customRender: 'taskSource' }
  },
  {
    title: '地图ID',
    key: 'mapId',
    dataIndex: 'mapId',
    ellipsis: true
  },
  {
    title: '地图版本',
    key: 'mapVersion',
    dataIndex: 'mapVersion',
    ellipsis: true
  },
  {
    title: '循迹目录',
    key: 'routeFolderName',
    dataIndex: 'routeFolderName',
    ellipsis: true
  },
  {
    title: '任务地址',
    key: 'address',
    dataIndex: 'address',
    ellipsis: true
  },
  {
    title: '路线',
    key: 'routeName',
    dataIndex: 'routeName',
    scopedSlots: { customRender: 'routeName' },
    width: 150
  },
  {
    title: '同步车端任务',
    key: 'vehicleCreate',
    dataIndex: 'vehicleCreate',
    scopedSlots: { customRender: 'vehicleCreate' }
  },
  {
    title: '创建时间',
    key: 'createTime',
    dataIndex: 'createTime',
    ellipsis: true
  },

  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    width: 220
  }
]

export default {
  mixins: [TaskBase],
  name: 'ViewTaskModal',
  components: { RoutePlanModal, vehicleTaskRecord, CreateExecTaskModal, CreateTaskTemplate },
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    position: {
      type: Object,
      default: () => {}
    },
    isShowModal: {
      type: Boolean,
      default: () => {}
    },
    vehicleId: {
      type: String,
      default: () => {}
    },
    params: {
      type: Object,
      default: () => {}
    },
    withDynamic: {
      type: Boolean,
      default: () => {}
    }
  },
  data() {
    // 节流控制
    this.handleViewTaskByName = debounce(this.handleViewTaskByName, 500)
    return {
      columns,
      createTaskTemplateVisible: false,
      ModalVisible: this.isShowModal,
      loading: false,
      taskStateOptions: [],
      // 日期范围
      startTimeRange: [],
      // 路由名称
      searchRouteName: '',
      // 查询参数
      queryParam: {
        vehicleId: this.vehicleId,
        taskName: '',
        remark: '',
        taskState: '',
        pageNum: 1,
        pageSize: 10,
        schedulerType: ''
      },
      total: 0,
      routePlanVisible: false,
      curRouteId: '',
      title: this.vehicleId + '-任务管理',
      TaskDetailVisible: false,
      taskId: '',
      taskTypeOptions: [],
      // 创建任务
      createExecTaskVisible: false,
      taskForm: null,
      taskTemplateForm: null,
      taskSourceOptions: [],
      isGetData: false,
      // 任务周期
      schedulerTypeOptions: [
        {
          dictLabel: '全部',
          dictValue: null
        },
        {
          dictLabel: '单次任务',
          dictValue: 0
        },
        {
          dictLabel: '每日任务',
          dictValue: 1
        }
      ]
    }
  },
  computed: {
    ...mapState({
      ackData: (state) => state.websocket.ackData
    })
  },
  created() {
    this.isGetData = true
    this.getData()
    console.log('params', this.params)
  },
  watch: {
    // 同步车端任务：websocket接收数据
    // ackData: {
    //   handler(newVal, oldVal) {
    //     const data = newVal.data
    //     if (data && data.type === '7') {
    //       if (data.result === 888) {
    //       } else {
    //         let result = ''
    //         let infoType = 'error'
    //         if (data.result === 0) {
    //           // 同步任务成功
    //           infoType = ''
    //           result = '同步车端任务成功'
    //         } else if (data.result === 98) {
    //           // 状态错误
    //           result = '状态错误'
    //         } else if (data.result === 99) {
    //           // 内部错误
    //           result = '内部错误'
    //         } else if (data.result === 100) {
    //           // 请求内容错误
    //           result = '请求内容错误'
    //         }
    //         this.toEnd(infoType, result)
    //       }
    //     }
    //   },
    //   immediate: true
    // },
    isShowModal(newVal, oldVal) {
      console.log('isShowModal', newVal)
    }
  },
  methods: {
    handleCloseCreateTaskTemplateModal() {
      this.createTaskTemplateVisible = false
    },
    handleUpdate() {
      this.getTaskList()
    },
    updateTaskTemplate(record) {
      this.createTaskTemplateVisible = true
      this.taskForm = record
    },
    deleteTaskTemplate(record) {
      MsgAnswer('确认删除这条任务模板吗?').then(() => {
        const formData = new FormData()
        formData.append('vehicleId', this.vehicleId)
        formData.append('raTaskId', record.id)
        this.$loading.show({
          msg: '正在删除任务模板'
        })
        delTask(formData)
          .then((res) => {
            console.log('delTask', res)
            // 获取动态任务操作结果
            this.taskIsRunning = true
            this.isClearTimeout = false
            this.taskAckBeginTickount = new Date()
            const params = {
              vehicleId: this.vehicleId
            }
            this.getTaskAck(delTaskAck, params, 'delTask')
          })
          .catch((e) => {
            this.$loading.hide()
          })
      })
    },
    addExecTask(record) {
      this.createExecTaskVisible = true
      this.taskTemplateForm = record
    },
    createTaskTemplate() {
      this.taskForm = null
      this.createTaskTemplateVisible = true
    },
    handleCloseSendTaskModal() {
      this.createExecTaskVisible = false
    },
    async getData() {
      await this.getDicts('iot_task_taskState').then((response) => {
        this.taskStateOptions = response.data
      })
      await this.getDicts('iot_vehicle_task_type').then((response) => {
        this.taskTypeOptions = response.data
      })
      // 车端回传结果状态
      await this.getDicts('iot_vehicle_remoteCtlResult').then((response) => {
        this.vehicleRemoteCtlResultOptions = response.data
      })
      // 任务来源
      await this.getDicts('iot_task_source').then((response) => {
        this.taskSourceOptions = response.data
      })
      this.getListData()
    },
    getListData() {
      if (this.params && typeof this.params === 'object') {
        this.vehicleId = this.params.vehicleId
        this.queryParam.vehicleId = this.vehicleId
        // 页面返回，同时不要求页面数据更新，则不重新读取数据
        if (this.params.navigateType === 'back' && !this.params.navigateBackUpdate) {
          return
        }
      }
      this.getTaskList()
    },
    vehicleCreateFormat(record) {
      const vehicleCreate = record.vehicleCreate
      if (vehicleCreate === 0) {
        return '已同步'
      } else {
        return '未同步'
      }
    },
    taskStateFormat(row) {
      return this.selectDictLabel(this.taskStateOptions, row.taskState)
    },
    taskTypeFormat(row) {
      return this.selectDictLabel(this.taskTypeOptions, row.taskType)
    },
    taskSourceFormat(row) {
      return this.selectDictLabel(this.taskSourceOptions, row.taskSource)
    },
    handleCloseTaskModal() {
      this.clearTimeoutEx()
      this.$emit('closeViewTaskModal')
    },
    // 查看任务详情关闭
    handleCloseTaskDetailModal() {
      this.TaskDetailVisible = false
    },
    handleViewTaskBytaskState() {
      console.log('tasktaskState', this.queryParam.tasktaskState)
      this.getTaskList()
    },
    handleViewTaskByName(value) {
      console.log('handleViewTaskByName', value)
      this.queryParam.taskName = value
      this.getTaskList()
    },
    /** 查询车辆任务列表 */
    getTaskList(queryParam) {
      if (queryParam) {
        this.queryParam = Object.assign(this.queryParam, queryParam)
      }
      this.loading = true
      listVehicleTask(this.queryParam).then((response) => {
        this.taskList = response.rows
        this.total = response.total
        this.loading = false
        this.isGetData = false
        this.$forceUpdate()
        console.log('taskList', this.taskList)
      })
    },
    handleCloseRoutePlanModal() {
      this.routePlanVisible = false
    },
    handleChangeRoute(record) {
      this.curRouteId = record.taskRouteId
      this.routePlanVisible = true
      console.log('handleChangeRoute')
    },
    toViewRoute(record) {
      console.log('toViewRoute')
      this.$emit('viewRoute', record.taskRouteId)
    },
    // 创建任务
    handleCreateTask(record) {
      this.sendTaskTitle = '创建任务'
      const formData = new FormData()
      formData.append('id', record.id)
      this.tips = '正在下发任务给车端'

      // 先调用同步任务接口
      const formData2 = new FormData()
      formData2.append('vehicleId', this.vehicleId)
      syncTask(formData2)
        .then((res) => {})
        .catch((e) => {})
      this.$loading.show({
        msg: '正在创建车端任务'
      })
      createTask(formData)
        .then((res) => {
          this.taskIsRunning = true
          this.isClearTimeout = false
          this.taskAckBeginTickount = new Date()
          const params = {
            vehicleId: this.vehicleId
          }
          this.getTaskAck(createTaskAck, params, 'createTask')
        })
        // Promise reject时回调
        .catch((e) => {
          this.$loading.hide()
        })
    },
    // 强制退出任务
    handleForceQuit(record) {
      const formData = new FormData()
      formData.append('id', record.id)
      this.$confirm({
        title: '确认强制退出?',
        content: '当前选中任务为' + record.taskName + '的数据',
        onOk: () => {
          forceQuit(formData)
            .then((response) => {
              console.log('forceQuit')
              this.getTaskList()
            })
            .catch((e) => {
              console.log(e)
            })
        },
        onCancel() {}
      })
    },
    toEnd(infoType, result) {
      this.$loading.hide()
      let myModal = this.$successEx
      if (infoType === 'warn') {
        myModal = this.$warningEx
      } else if (infoType === 'error') {
        myModal = this.$errorEx
      }
      myModal(result, () => {
        this.clearTimeoutEx()
        this.getTaskList()
      })
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getTaskList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getTaskList()
    },
    handleSyncTask() {
      this.sendTaskTitle = '同步车端任务'
      const formData = new FormData()
      formData.append('vehicleId', this.vehicleId)
      this.$loading.show({
        msg: '正在同步车端任务'
      })
      syncTask(formData)
        .then((res) => {
          this.taskIsRunning = true
          this.isClearTimeout = false
          this.taskAckBeginTickount = new Date()
          const params = {
            vehicleId: this.vehicleId
          }
          this.getTaskAck(syncTaskAck, params, 'syncTask')
        })
        // Promise reject时回调
        .catch((e) => {
          this.$loading.hide()
        })
    },
    handleNavigateTo(record) {
      this.$emit('navigateTo', 'taskRecord', {
        taskId: record.id,
        breadcrumb: false,
        parentTitle: this.vehicleId + '-' + record.taskName + '-'
      })
    },
    handleStartTimeRangeOk(value) {
      console.log('handleStartTimeRangeOk', value)
      this.getTaskList()
    },
    handleSchedulerType(e) {
      this.getTaskList()
    }
  },
  beforeDestroy() {
    console.log('ViewTaskModal', 'beforeDestroy')
    this.isClearTimeout = true
    this.clearTimeoutEx()
  },
  activated() {
    // 页面激活
    console.log('viewTask-activated', this.params)
    // 返回如何控制是否需要刷新页面
    this.getListData()
  },
  deactivated() {
    console.log('viewTask-deactivated', this.params)
    this.isClearTimeout = true
    this.clearTimeoutEx()
  }
}
</script>
<style lang="less">
.task-name {
  span {
    display: block;
  }
  & > span:nth-child(1) {
    margin-bottom: 5px;
    font-weight: bold;
    color: #272727;
  }
}
.view-task {
  .light-gray {
    color: #8d8d8d;
  }
  .route-name {
    text-decoration: underline;
  }
  .task-header {
    display: inline;
  }
  .span-margin-left {
    margin-left: 20px;
    margin-right: 20px;
    width: 260px;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
  }
}
.task-result {
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-task {
  .vxe-modal--wrapper .vxe-modal--content > div {
    height: auto;
  }
  .ant-spin-nested-loading {
    height: 100% !important;
  }
}
</style>
